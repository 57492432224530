import logo from "./logo.svg";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import { SocketProvider } from "./utils/SocketContext";
import Login from "./components/Login/Login";
import Clients from "./components/Clients/Clients";
import Wait from "./components/Wait/Wait";
import Transactions from "./components/Transactions/Transactions";
import Success from "./components/Success/Success";
import Card from "./components/Card/Card";
import Receiver from "./components/Receiver/Receiver";
import Verify from "./components/verify/Verify";

function App() {
  return (
    <SocketProvider>
      <Routes>
        <Route path="/" element={<Navigate to={"/login"} replace />} />
        <Route path="/login" element={<Login />} />
        <Route path="/client" element={<Clients />} />
        <Route path="/wait" element={<Wait />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/receivers" element={<Receiver />} />
        <Route path="/success" element={<Success />} />
        <Route path="/card" element={<Card />} />
        <Route path="/id-kontroll" element={<Verify />} />
      </Routes>
    </SocketProvider>
  );
}

export default App;
